import { render, staticRenderFns } from "./FlashMessages.vue?vue&type=template&id=47e788e6&scoped=true&"
import script from "./FlashMessages.vue?vue&type=script&lang=js&"
export * from "./FlashMessages.vue?vue&type=script&lang=js&"
import style0 from "./FlashMessages.vue?vue&type=style&index=0&id=47e788e6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47e788e6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/pbk/pim/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('47e788e6')) {
      api.createRecord('47e788e6', component.options)
    } else {
      api.reload('47e788e6', component.options)
    }
    module.hot.accept("./FlashMessages.vue?vue&type=template&id=47e788e6&scoped=true&", function () {
      api.rerender('47e788e6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "modules/@ergonode/core/src/components/FlashMessages/FlashMessages.vue"
export default component.exports